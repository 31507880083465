import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@pluggyai/ui'

import { Page } from '../Page/'
import { Props } from './BillingPage.types'

import './BillingPage.css'

const BillingPage = ({
  error,
  isLoading,
  billingDashboardUrl,
  onFetchBillingDashboard,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  useEffect(() => {
    onFetchBillingDashboard()
  }, [onFetchBillingDashboard])

  return (
    <Page className={'BillingPage'}>
      <div className={'billing-container'}>
        <h1 className={'title'}>{t('billing.title')}</h1>
        <div className={'content'}>
          {error && (
            <Alert
              type={'error'}
              size={'medium'}
              message={t('billing.error')}
            />
          )}
          {isLoading && <div className={'skeleton iframe-container'} />}
          {billingDashboardUrl && !isLoading && (
            <iframe
              src={billingDashboardUrl}
              title={'Reporting'}
              width={'100%'}
              height={'100%'}
              className={'iframe-container'}
            />
          )}
        </div>
      </div>
    </Page>
  )
}

export default memo(BillingPage)
