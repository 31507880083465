import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Menu, MenuItemProps } from 'semantic-ui-react'

import { locations } from '../../../modules/routing/locations'

type Props = {
  activeItem: string | null
  onNavigate: (path: string) => void
}

const NavigationItems = ({ activeItem, onNavigate }: Props): JSX.Element => {
  const { t } = useTranslation()

  const handleItemClick = useCallback(
    (event: React.MouseEvent, { name }: MenuItemProps) => {
      event.preventDefault()
      if (name === undefined) {
        console.error("No name specified, can't navigate")
        return
      }
      onNavigate(name)
    },
    [onNavigate],
  )

  return (
    <>
      <Menu.Item
        as="a"
        name={locations.overview()}
        href={locations.overview()}
        active={
          activeItem === locations.overview() || activeItem === locations.root()
        }
        onClick={handleItemClick}
      >
        {t('navbar.link.overview')}
      </Menu.Item>
      <Menu.Item
        as="a"
        name={locations.events()}
        href={locations.events()}
        active={activeItem === locations.events()}
        onClick={handleItemClick}
      >
        {t('navbar.link.events')}
      </Menu.Item>
      <Menu.Item
        as="a"
        name={locations.executions()}
        href={locations.executions()}
        active={activeItem === locations.executions()}
        onClick={handleItemClick}
      >
        {t('navbar.link.executions')}
      </Menu.Item>
      <Menu.Item
        as="a"
        name={locations.applications()}
        href={locations.applications()}
        active={activeItem?.startsWith(locations.applications())}
        onClick={handleItemClick}
      >
        {t('navbar.link.applications')}
      </Menu.Item>
      <Menu.Item
        as="a"
        name={locations.customization()}
        href={locations.customization()}
        active={activeItem?.startsWith(locations.customization())}
        onClick={handleItemClick}
      >
        {t('navbar.link.customization')}
      </Menu.Item>

      <Menu.Item
        name={locations.settings()}
        href={locations.settings()}
        active={activeItem?.startsWith(locations.settings())}
        onClick={handleItemClick}
      >
        {t('navbar.link.settings')}
      </Menu.Item>

      <Menu.Item
        name={locations.billing()}
        href={locations.billing()}
        active={activeItem?.startsWith(locations.billing())}
        onClick={handleItemClick}
      >
        {t('navbar.link.billing')}
      </Menu.Item>
    </>
  )
}

export { NavigationItems }
